<template>
  <section id="dashboard-analytics">
    <b-row>
        <b-col>
          <Statistics />
        </b-col>
    </b-row>

    <!-- <b-row class="match-height">
      <b-col lg="12">
        <SalesLineChart class="py-0 mt-1" :design="'badge'" />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <b-col lg="12">
        <Calendar class="py-0" :design="'badge'" />
      </b-col>
    </b-row>
    <!-- <b-row class="match-height">
          <b-col lg="12">
            <analytics-avg-sessions :data="data.avgSessions" />
          </b-col>
        </b-row> -->

    <!-- <b-row class="match-height">
      <b-col lg="4">
        <analytics-timeline :data="data.timeline" />
      </b-col>
      <b-col lg="4">
        <analytics-sales-radar-chart :data="data.salesChart" />
      </b-col>
      <b-col lg="4">
        <analytics-app-design :data="data.appDesign" />
      </b-col>
    </b-row> -->

    <!-- <b-row>
      <b-col cols="12">
        <invoice-list />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import SalesLineChart from "@/views/main/views/dashboard/components/SalesLineChart.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import AnalyticsCongratulation from "@/views/dashboard/analytics/AnalyticsCongratulation.vue";
import AnalyticsAvgSessions from "@/views/dashboard/analytics/AnalyticsAvgSessions.vue";
import AnalyticsSupportTracker from "@/views/dashboard/analytics/AnalyticsSupportTracker.vue";
import AnalyticsTimeline from "@/views/dashboard/analytics/AnalyticsTimeline.vue";
import AnalyticsSalesRadarChart from "@/views/dashboard/analytics/AnalyticsSalesRadarChart.vue";
import AnalyticsAppDesign from "@/views/dashboard/analytics/AnalyticsAppDesign.vue";
import Calendar from "@/views/main/views/dashboard/components/CalendarReserve.vue";
import Statistics from "@/views/card/card-statistic/CardStatisticsGroup.vue";

export default {
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    Calendar,
    Statistics,
    SalesLineChart,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    // data
    this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
    });
  },
  methods: {
    kFormatter,
  },
};
</script>
